import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Layout from './components/Layout/Layout';
import ContactPage from './pages/Contact';
import AboutPage from './pages/About';
import ServicesPage from './pages/Services';
import HomePage from './pages/Home';
import './App.css'



function App() {
  return (
    <div className="App">
      <Router>
        <Layout>
          <Route exact path="/" render={props => <HomePage {...props} />} />
          <Route path="/hakkimizda" render={props => <AboutPage {...props} />} />
          <Route path="/hizmetlerimiz" render={props => <ServicesPage {...props} />} />
          <Route path="/iletisim" render={props => <ContactPage {...props} />} />
        </Layout>
      </Router>

    </div>
  );
}

export default App;
