import React from 'react';
import { Paper, Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HiChip , HiDatabase,  HiOutlineDesktopComputer} from "react-icons/hi";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 50,
        marginBottom: 50,
    },

    kucuk: {
        paddingBottom:30,
        height:200,
        transition:'1s',
        '&:hover': {
           transform:'scale(1.05)',
        },
     },
  
     icons: {
        fontSize:60,
        marginTop:30,
        color: theme.palette.secondary.main,
     },
  
     baslik: {
        fontSize:20,
        paddingTop:10,
        paddingBottom:10,
        color: theme.palette.primary.main,
     },
  
     yazi: {
        
        fontSize:16,
        padding:10,
  
     },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        transition: '1s',
        '&:hover': {
            transform: 'scale(1.1)',
        },
    },
}));


const HomeIcon = () => {
    const classes = useStyles();


    return (
        <div className={classes.root}>

            <Grid container spacing={3}>
                <Grid item md={4} sm={6} xs={12}>
                    <Paper xs={12} sm={6} className={classes.kucuk}>
                        <Box display="flex"
                            flexDirection="column"
                            alignItems="center">
                            <HiChip className={classes.icons} />
                        </Box>

                        <Typography variant="subtitle1" align="center" className={classes.baslik}>
                            
                        </Typography>
                        <Typography align="center" className={classes.yazi}>
                        Bilişim ve Yazılım Alanında firmaların istekleri ve sorunları doğrultusunda çözümler üretmekteyiz.
                        </Typography>
                    </Paper>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Paper xs={12} sm={6} className={classes.kucuk}>
                        <Box display="flex"
                            flexDirection="column"
                            alignItems="center">
                            <HiDatabase className={classes.icons} />
                        </Box>

                        <Typography variant="subtitle1" align="center" className={classes.baslik}>
                            
                        </Typography>
                        <Typography align="center" className={classes.yazi}>
                        Bakım ve Uzaktan Destek Anlaşmalası 
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                    <Paper xs={12} sm={6} className={classes.kucuk}>
                        <Box display="flex"
                            flexDirection="column"
                            alignItems="center">
                            <HiOutlineDesktopComputer className={classes.icons} />
                        </Box>

                        <Typography variant="subtitle1" align="center" className={classes.baslik}>
                            
                        </Typography>
                        <Typography align="center" className={classes.yazi}>
                        İsteğe Bağlı Yazılım Üretimi
                        </Typography>
                    </Paper>
                </Grid>

            </Grid>


        </div>
    )
}

export default HomeIcon
