import React from "react";

export default function Contactmap() {
	return (
		<div 	style={{ paddingTop:'10px', paddingBottom:'10px'	}}	>
            <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3059.7366672671283!2d32.846580351433325!3d39.924908879324406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34e3a39c0b78d%3A0xcfc0ee1551592b2!2sPctek%20Bilgisayar!5e0!3m2!1str!2str!4v1616765529697!5m2!1str!2str"
                  
                  width={"100%"}
                  height={"300"}
                  title="My Daily Marathon Tracker"
                  frameBorder={0}
                  tabIndex={0}
            />
		</div>
	);
}

