import React from 'react';
import {Box, Grid, IconButton, makeStyles, Typography} from "@material-ui/core";
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import MapPinIcon from '@material-ui/icons/Map';



const useStyles = makeStyles((theme) => ({
   contactCard: {
      display: "flex",
      marginTop: theme.spacing(1),
      alignItems: "center",
   },
   icon: {
      color: theme.palette.primary.main,
      marginRight: theme.spacing(0),

      alignItems: "center",
      justifyContent: "center",
   },
   iconButton: {
      backgroundColor: theme.palette.secondary.main,
      marginRight: theme.spacing(2),
   },
}));


export default function Contacticons() {
   const classes = useStyles();


   return (
      <div>
         <Grid xs={12}>
            <Grid item xs={12}>
               <Box className={classes.contactCard}>
                  <IconButton className={classes.iconButton}>
                     <PhoneIcon className={classes.icon} />
                  </IconButton>

                  <Box>
                     <Typography variant="subtitle1">
                        Telefon
                           </Typography>
                     <Typography>(0312) 230 68 26</Typography>
                  </Box>
               </Box>
               <hr />
            </Grid>
            <Grid item xs={12}>
               <Box className={classes.contactCard}>
                  <IconButton className={classes.iconButton}>
                     <PhoneIcon className={classes.icon} />
                  </IconButton>

                  <Box>
                     <Typography variant="subtitle1">
                        Cep Telefonu
                           </Typography>
                     <Typography>+90 543 908 61 00</Typography>
                  </Box>
               </Box>
               <hr />
            </Grid>
            <Grid item xs={12}>
               <Box className={classes.contactCard}>
                  <IconButton className={classes.iconButton}>
                     <MailIcon className={classes.icon} />
                  </IconButton>
                  <Box>
                     <Typography variant="subtitle1">
                        E-mail
                           </Typography>
                     <Typography>serkan@pctek.com.tr</Typography>
                  </Box>
               </Box>
               <hr />
            </Grid>
            <Grid item xs={12}>
               <Box className={classes.contactCard}>
                  <IconButton className={classes.iconButton}>
                     <MapPinIcon className={classes.icon} />
                  </IconButton>

                  <Box>
                     <Typography variant="subtitle1">
                        Adres
                           </Typography>
                     <Typography>
                     Korkut Reis Mah., Necatibey Cad., Yeşilırmak Sok. 
                     No:10 Daire:10, 06430 Çankaya/Ankara
                           </Typography>
                  </Box>
               </Box>
            </Grid>
         </Grid>
      </div>
   )
}
