import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {  Container, Grid, Paper } from '@material-ui/core';
import Contactmap from './../components/Contactmap';
import Contacticons from './../components/Contacticons';
import Contactform from './../components/Contactform';
import Contactyazi from '../components/Contactyazi';


const useStyles = makeStyles((theme) => ({
   root: {
      flexGrow: 1,
      marginBottom:40,
      marginTop:30,
    },
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.primary,
    },
  }));



export default function ContactPage() {
   const classes = useStyles();

   return (
      <div className={classes.root} >
         <Container>
      <Grid container spacing={3} >
        <Grid item xs={12} >
          <Paper className={classes.paper} ><Contactmap/></Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}><Contactyazi/></Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}><Contacticons/></Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}><Contactform/></Paper>
        </Grid>

      </Grid>
      </Container>
    </div>
   )
}
