import React from "react";
import {Box, Container, Grid, makeStyles, Typography,} from "@material-ui/core";
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import MapPinIcon from '@material-ui/icons/Map';
import Contactmap from "../Contactmap";



const useStyles = makeStyles((theme) => ({
   footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      backgroundColor: '#ebeef0',
      paddingTop: 50,
      paddingBottom: 50,

   },

   contactText: {
      marginBottom: 0,
      color: '#032b60',
      fontSize: '14px',
      marginLeft: '6px',
      borderBottom: '1px solid white',
   },

   socialButton: {
      marginLeft: 10,
      marginBottom: 10,
      marginRight: 10,
      fontSize:50,
   },
   paragraf: {
      fontSize: '14px',
      textAlign: 'justify',
      lineHeight: '24px'
   },

   baslik: {
      fontSize: '20px',
      paddingBottom: '10px',
      color: '#032b60',
   },

   contactCard: {
      display: "flex",
      marginTop: theme.spacing(),
      alignItems: "center",
   },
   icon: {
      color: theme.palette.secondary.main,
      fontSize: '40px',
      marginRight: theme.spacing(2),
      alignItems: "center",
      justifyContent: "center",
   },
   iconButton: {
      backgroundColor: "#032b60",
      marginRight: theme.spacing(2),
   },

   sosyal: {
      marginTop: '30px',
   }


}));

const Footer = () => {
   const classes = useStyles();

   return (
      <footer className={classes.footer}>
         <Box width="100%" >
            <Container >
               <Grid container spacing={5}>

                  <Grid item sm={7} xs={12}>
                     <Contactmap/>

                  </Grid>


                

                  <Grid item sm={5} xs={12}>
                     <Typography variant="subtitle1" className={classes.baslik}>
                        İLETİŞİM
                     </Typography>

                     <Grid item xs={12}>
                        <Box className={classes.contactCard}>
                           <PhoneIcon className={classes.icon} />
                           <Box>
                              <Typography>(0312) 230 68 26</Typography>
                           </Box>
                        </Box>
                        <hr />
                     </Grid>

                     <Grid item xs={12}>
                        <Box className={classes.contactCard}>
                           <PhoneIcon className={classes.icon} />
                           <Box>
                              <Typography>+90 543 908 61 00</Typography>
                           </Box>
                        </Box>
                        <hr />
                     </Grid>

                     <Grid item xs={12}>
                        <Box className={classes.contactCard}>
                           <MailIcon className={classes.icon} />
                           <Box>
                              <Typography>serkan@pctek.com.tr</Typography>
                           </Box>

                        </Box>
                        <hr />
                     </Grid>

                     <Grid item xs={12}>
                        <Box className={classes.contactCard}>
                           <MapPinIcon className={classes.icon} />
                           <Box>
                              <Typography>
                              Korkut Reis Mah., Necatibey Cad., Yeşilırmak Sok. 
                              No:10 Daire:10, 06430 Çankaya/Ankara
                              </Typography>
                           </Box>
                        </Box>
                        <hr />
                     </Grid>

                     <Box
                        display="flex"
                        width="100%"
                        marginLeft={0}
                        marginTop={0}
                        marginBottom={0}
                     >

                     </Box>


                  </Grid>
               </Grid>
            </Container>
         </Box>
      </footer>
   );
};

export default Footer;
