import { createMuiTheme } from '@material-ui/core/styles';


const theme = createMuiTheme({
   typography: {
      fontFamily: 'Exo',
    },

  palette: {
    primary: {
      main: '#4235e2',
    },

    secondary: {
      main: '#01a0e2',
    },

    background: {
      default: '#fff',
    },
  },
});

export default theme;