import React from 'react';
import Topbar from './Topbar';
import Footer from './Footer';
import {withRouter} from 'react-router-dom';


const Layout = ({ children}) => {

   return (
      <div>
         <Topbar />
         
         <main>
          {children}
         </main>
         <Footer/>
      </div>
   )
}

export default withRouter(Layout);

