import React from 'react';
import {Box, Grid, Typography} from '@material-ui/core';

export default function Contactyazi() {
   return (
      <div>
         <Grid item container sm={12} marginBottom={4}>
            <Grid item xs={12}>
               <Typography variant="h4" gutterBottom text-primary>
                  BİZE ULAŞIN
              </Typography>
               <Box>
                  <Typography>
                     Sormak istediğiniz her konuda bize buradan mesaj gönderebilirsiniz. Size ulaşabilmemiz için, tercihen e-mail adresinizi veya telefon numaranızdan en az birini belirtmelisiniz. En kısa sürede tarafımızdan iletişim kurulacaktır. İlginizden dolayı şimdiden teşekkür ederiz.
                </Typography>
               </Box>
            </Grid>
         </Grid>
      </div>
   )
}
