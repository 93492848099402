import React from 'react';
import {Paper, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

const useStyles  = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop:50,
      marginBottom:50,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      transition:'1s',
      '&:hover': {
         transform:'scale(1.1)',
        },
    },
  }));


const HomeResim = () => {
    const classes = useStyles ();


    return (
        <div className={classes.root}>

                <Grid container spacing={3}>
                    <Grid item md={4} sm={6} xs={12}>
                        <Paper className={classes.paper}>
                        <img src={'./img/img1.png'} width="100%" height="250" borderRadius='10px' alt=""/>
                        </Paper>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Paper className={classes.paper}>
                        <img src={'./img/1.jpg'} width="100%" height="250" alt="" />
                        </Paper>
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <Paper className={classes.paper}>
                        <img src={'./img/img7.jpg'} width="100%" height="250" alt="" />
                        </Paper>
                    </Grid>
                </Grid>

            
        </div>
    )
}

export default HomeResim
