import React from 'react';
import { Container } from '@material-ui/core';
import Home1 from '../components/Home1';
import HomeResim from '../components/HomeResim';
import HomeIcon from '../components/HomeIcon';
import HomeGiris from '../components/HomeGiris';



const HomePage = () => {
   return (
      <div>
         <Container>
            <div className="paper" >
            <HomeGiris className="paper" />
            </div>
            <HomeIcon  />
            <Home1/>
            <HomeResim/>
         </Container>
      </div>
   )
}

export default HomePage