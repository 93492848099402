import React from 'react'
import { Container } from '@material-ui/core'


const AboutPage = () => {
   return (
      <div>
         <Container>
         <h3>Hakkımızda Sayfası</h3>
         </Container>
         
      </div>
   )
}

export default AboutPage
