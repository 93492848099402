import React from 'react';
import {Grid} from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


export default function Contactform() {
   return (
      <div>
         <Grid item container sm={12} spacing={2} >
               <Grid item container sm={12}>
                  <TextField size="small" fullWidth label="Adınız" variant="outlined" />
               </Grid>
               <Grid item container sm={12}>
                  <TextField size="small" fullWidth label="Telefon" variant="outlined" />
               </Grid>
               <Grid item container sm={12}>
                  <TextField size="small" fullWidth label="Mail" variant="outlined" />
               </Grid>
               <Grid item container sm={12}>
                  <TextField size="small" fullWidth label="Konu" variant="outlined" multiline rows={2} />
               </Grid>
               <Grid item container sm={12}>
                  <Button variant="outlined" color="primary" fullWidth >  GÖNDER </Button>
               </Grid>
         </Grid>


      </div>

   )
}
