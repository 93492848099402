import React from 'react';
import {  Grid } from '@material-ui/core';
import Typed from 'react-typed';


const HomeGiris = () => {
   return (
      <div className="home">
         <Grid container>
            <Grid item xs={12}sm={6}>
            <div className=" yazi">
            <Typed
                    strings={['PCTEK Bilgisayar, Bilişim ve Yazılım Destek Olarak Yanınızda...']}
                    typeSpeed={80}
                />
            </div>
            </Grid>
            <Grid item xs={12}sm={6}>
               <img src={'./img/img2.jpg'} width="100%" height="100%" alt="" />
            </Grid>
         </Grid>
      </div>
   )
}

export default HomeGiris
