import React from "react";
import {
  AppBar,
  Toolbar,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
  root: {
    "&$selected": {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: 10,
      color: "white",
    },
  },
  selected: {},

  tabs: {
    fontSize: 16,
  },

  list: {
    display: "flex",
    color: "black",
    fontSize: 20,
  },

  aa: {
    flexGrow: 1,
    paddingTop:5,
    paddingBottom:5,
  },
}));

const Topbar = () => {
  const classes = useStyles();

  return (
    <div className={classes.mobilmenu}>
      <AppBar
        position="static"
        style={{ backgroundColor: "white", color: "black" }}
      >
        <Container>
          <Toolbar>
            <div className={classes.aa}>
              <img
                src="./img/pctek.jpg"
                style={{ height: "80px", width: "240px" }}
                alt=""
              />
            </div>


          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
};

export default Topbar;
