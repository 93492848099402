import React from 'react';
import {  Grid, Typography } from '@material-ui/core';


const Home1 = () => {
   return (
      <div className="">
         <Grid container>
         <Grid item xs={12}sm={6}>
            <img src={'./img/15.png'} width="100%" height="100%" alt="" />
            </Grid>
            <Grid item xs={12}sm={6}>
               <Typography variant="h4" style={{paddingBottom:'30px', paddingTop:'50px', color:'#4235e2'}}>
                  HAKKIMIZDA
               </Typography>
               <Typography  style={{fontSize:'18px'}} >
               Firmamız 2014 yılında kuruldu. bilişim ve yazılım(logo) alanında firmaların istekleri ve 
               sorunları doğrultusunda çözümler üretmekteyiz. 
               Aynı zamanda  bilişim konusunda yeni çıkan 
               teknolojik gelişmelerden haberdar ederek çözümler sunmaktayız.
               2018 yılından itibaren yazılım alanında firma talepleri doğrultusunda 
               yazılım üretmekteyiz.
               </Typography>
            </Grid>
         </Grid>
      </div>
   )
}

export default Home1
